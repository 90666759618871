<template>
  <div class="container">
    <div class="my-3">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th scope="col">№</th>
          <th scope="col">ФИО ППС</th>
          <th scope="col">Общее количество баллов</th>
          <th scope="col">Индекс достижения цели</th>
          <th scope="col">Решение</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(kpiTeacher,i) in kpiTeachers" :key="i">
          <td>{{ i + 1 }}</td>
          <td>
            <a @click.prevent="goToUser(kpiTeacher.user_id)" class="text-decoration-none" href="#">
              {{ kpiTeacher.lastname }} {{ kpiTeacher.firstname }}
            </a>
          </td>
          <td>
            <router-link :to="`/kpi-commission-teacher?user_id=${kpiTeacher.user_id}`">
              {{ kpiTeacher.total_score }}
            </router-link>
          </td>
          <td>{{ kpiTeacher.index }}</td>
          <td class="kpi-td">
            <div class="kpi-td-buttons" v-if="kpiTeacher.status === null">
              <button class="btn btn-primary" @click="openModal(i, 1)">Принять</button>
              <button class="btn btn-danger" @click="openModal(i, 2)">Отказать</button>
            </div>
            <div v-else>
              <div v-if="kpiTeacher.status === '1'">Приянто</div>
              <div v-else>Отказано</div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <DecisionModal :modalKpi="modalKpi" @setKpiTeacherStatus="setKpiTeacherStatus"/>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import DecisionModal from "@/components/kpi/DecisionModal.vue";

export default {
  name: "KpiTeachersDepartmentHead",
  components: {
    DecisionModal,
  },
  data() {
    return {
      modalKpi: {
        displayDecisionModal: false,
        kpiDecisionId: null,
        kpiTeacherLastName: '',
        kpiTeacherFirstName: '',
        status: 0,
        comment: ''
      }
    }
  },
  methods: {
    ...mapActions('kpiTeachersDepartmentHead', ['GET_KPI_TEACHERS_BY_DEPARTMENT_ID', 'PUT_KPI_TEACHER_STATUS']),
    ...mapMutations('userinfo', ['SET_USER_ID']),

    setKpiTeacherStatus(objectData = {}) {
      if (Object.keys(objectData).length > 0) {
        if (this.PUT_KPI_TEACHER_STATUS({
          id: this.modalKpi.kpiDecisionId,
          status: this.modalKpi.status,
          comment: objectData.comment
        })) {
          this.$message({title: 'Статус изменен'})
          this.GET_KPI_TEACHERS_BY_DEPARTMENT_ID()
        }
      }
      this.clearModalKpiObject()
    },
    openModal(index, status) {
      this.modalKpi = {
        displayDecisionModal: true,
        kpiDecisionId: this.kpiTeachers[index].id,
        kpiTeacherLastName: this.kpiTeachers[index].lastname,
        kpiTeacherFirstName: this.kpiTeachers[index].firstname,
        status: status,
        comment: ''
      }
    },
    clearModalKpiObject() {
      this.modalKpi = {
        displayDecisionModal: false,
        kpiDecisionId: null,
        kpiTeacherLastName: '',
        kpiTeacherFirstName: '',
        status: 0,
        comment: ''
      }
    },
    goToUser(userId) {
      this.SET_USER_ID(userId)
      this.$router.push('/user')
    },
  },
  async mounted() {
    await this.GET_KPI_TEACHERS_BY_DEPARTMENT_ID()
  },
  computed: {
    ...mapState('kpiTeachersDepartmentHead', ['kpiTeachers']),
  },
}
</script>

<style scoped>
.kpi-td-buttons button{
  margin: 0 5px;
}
</style>
