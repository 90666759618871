<template>
  <!-- add contract dialog -->
  <Dialog header="Принять или отказать" :visible="modalKpi.displayDecisionModal" :closable="false" :modal="true"
          :style="{width: '100%', maxWidth: '600px'}">
    <div class="my-3">
      <h5>{{ modalKpi.kpiTeacherLastName }} {{ modalKpi.kpiTeacherFirstName }}</h5>

      <div class="form-group row mt-4">
        <label for="contract_start_date" class="col-md-3 col-form-label">
          Оставьте комментарий
        </label>
        <div class="col-md-9">
          <input id="contract_start_date" type="text" class="form-control" v-model="comment">
        </div>
      </div>
    </div>

    <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeDialog" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-plus" @click="addStatus" autofocus/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "DecisionModal",
  data() {
    return {
      status: null,
      comment: ''
    }
  },
  props: {
    modalKpi: {
      type: Object
    },
  },
  methods: {
    addStatus() {
      this.$emit("setKpiTeacherStatus", {comment: this.comment})
      this.comment = ''
    },
    closeDialog() {
      this.$emit("setKpiTeacherStatus")
    },
  }
}
</script>

<style scoped>

</style>